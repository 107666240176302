/**
 * @generated SignedSource<<6d2b4e1323818a35deff59e5f2981eda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSharedUrgencySignals_item$data = ReadonlyArray<{
  readonly serviceId: string | null;
  readonly " $fragmentType": "useSharedUrgencySignals_item";
}>;
export type useSharedUrgencySignals_item$key = ReadonlyArray<{
  readonly " $data"?: useSharedUrgencySignals_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSharedUrgencySignals_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useSharedUrgencySignals_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "1e86f03f16f7008b5a0b8cb5e830744c";

export default node;
