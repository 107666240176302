import { useCallback, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';

import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { getUserSessionRegions } from 'dibs-regional-info/exports/regionalInfoHelpers';

import { useSearchCorrections } from '../../useSearchCorrections';
import {
    trackEcommerceProductClick,
    trackEcommerceProductImpressions,
    ItemData,
    UrgencySignal,
} from '../../../utils/tracking/searchBrowse/ecommerceTracking';
import { useSbSelector } from '../../../reducers/useSbSelector';
import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';

import { useSbSharedItemTracking_item$key } from './__generated__/useSbSharedItemTracking_item.graphql';
import { useSbSharedItemTracking_itemSearch$key } from './__generated__/useSbSharedItemTracking_itemSearch.graphql';
import { useSbSharedItemTracking_viewer$key } from './__generated__/useSbSharedItemTracking_viewer.graphql';

const viewerFragment = graphql`
    fragment useSbSharedItemTracking_viewer on Viewer {
        regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
            @include(if: $fetchRegionalInfo) {
            regionsByZipCode {
                displayName
            }
        }
    }
`;

const itemSearchFragment = graphql`
    fragment useSbSharedItemTracking_itemSearch on ItemSearchQueryConnection {
        ...ecommerceTracking_itemSearch
        ...useSearchCorrections_itemSearch
    }
`;

const itemFragment = graphql`
    fragment useSbSharedItemTracking_item on Item @relay(plural: true) {
        ...ecommerceTracking_item
    }
`;

type TrackingFunctionArgs = {
    itemId: string | null;
    index: number;
    isSponsored?: boolean;
};

export type TrackingFunction = (props: TrackingFunctionArgs) => void;

type Item = Omit<ItemData, 'urgencySignal'>;
type Args = {
    viewer: useSbSharedItemTracking_viewer$key;
    itemSearch: useSbSharedItemTracking_itemSearch$key;
    items: useSbSharedItemTracking_item$key | null | undefined;
    pageType?: string;
    getUrgencySignal?: (itemId: string | null | undefined) => UrgencySignal;
};

const dedupeItem = ({ itemId, index }: { itemId: string; index: number }): string =>
    `${index}_${itemId}`;

export const useSbSharedItemTracking = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    items: itemsRef,
    pageType,
    getUrgencySignal,
}: Args): {
    fireItemClickTracking: TrackingFunction;
    fireItemImpressionTracking: TrackingFunction;
} => {
    const [trackedItemImpressionItems, setTrackedItemImpressionItems] = useState<string[]>([]);
    const [attemptedItemImpressionItems, setAttemptedItemImpressionItems] = useState<Item[]>([]);

    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const items = useFragment(itemFragment, itemsRef);
    const [searchCorrectionTerm] = useSearchCorrections({ itemSearch });

    const { isMobile } = useServerVarsContext();
    const pageSize = useSbSelector(state => state.relayVariables.variables.first) || 60;
    const page = useSbSelector(state => state.filters.page);

    const regionalInfoAvailable = useCallback(() => {
        return !!(getUserSessionRegions() || viewer?.regionalInfo);
    }, [viewer]);

    const getRegions = useCallback(() => {
        return getUserSessionRegions() || viewer?.regionalInfo?.[0]?.regionsByZipCode || [];
    }, [viewer]);

    const trackImpressions = useCallback(
        (impressionItems: Item[]): void => {
            const userRegions = getRegions();
            const itemIds = impressionItems.map(({ itemId }) => itemId);
            const itemsData = impressionItems.map(impressionItem => {
                const urgencySignal = getUrgencySignal?.(impressionItem.itemId) || null;
                return { ...impressionItem, urgencySignal };
            }, <ItemData[]>[]);
            trackEcommerceProductImpressions({
                items,
                itemSearch,
                itemsData,
                searchCorrectionTerm,
                userRegions,
                pageType,
                page,
                pageSize,
                isMobile,
            });
            setTrackedItemImpressionItems(curr => [...curr, ...itemsData.map(dedupeItem)]);

            // filtering out only items passed to `trackImpressions()`, and leaving still pending for urgency signals
            setAttemptedItemImpressionItems(prev =>
                prev.filter(({ itemId }) => !itemIds.includes(itemId))
            );
        },
        [
            getRegions,
            items,
            itemSearch,
            getUrgencySignal,
            searchCorrectionTerm,
            pageType,
            page,
            pageSize,
            isMobile,
        ]
    );

    const [debouncedTracking] = useDebouncedCallback(trackImpressions, 200);

    useEffect(() => {
        if (attemptedItemImpressionItems.length > 0 && regionalInfoAvailable()) {
            debouncedTracking(attemptedItemImpressionItems);
        }
    }, [attemptedItemImpressionItems, debouncedTracking, regionalInfoAvailable]);

    const fireItemImpressionTracking: TrackingFunction = useCallback(
        ({ itemId, index, isSponsored }) => {
            if (itemId) {
                const dedupedItem = dedupeItem({ itemId, index });
                //Checking if item impression is already fired or it is already in attemptedItemImpressionItems list
                if (
                    !trackedItemImpressionItems.find(
                        impressionItem => impressionItem === dedupedItem
                    ) &&
                    !attemptedItemImpressionItems
                        .map(attemptedItem => dedupeItem(attemptedItem))
                        .find(impressionItem => impressionItem === dedupedItem)
                ) {
                    setAttemptedItemImpressionItems(curr => [
                        ...curr,
                        { itemId, index, isSponsored },
                    ]);
                }
            }
        },
        [attemptedItemImpressionItems, trackedItemImpressionItems]
    );

    const fireItemClickTracking: TrackingFunction = useCallback(
        ({ itemId, index, isSponsored }) => {
            if (itemId) {
                const userRegions = getRegions();
                //In case clicking on item while impression tracking haven't been done
                if (!trackedItemImpressionItems.includes(dedupeItem({ itemId, index }))) {
                    trackImpressions([
                        {
                            itemId,
                            index,
                            isSponsored,
                        },
                    ]);
                    setAttemptedItemImpressionItems([]);
                }

                trackEcommerceProductClick({
                    itemSearch,
                    items,
                    index,
                    searchCorrectionTerm,
                    isSponsored,
                    pageType,
                    userRegions,
                    page,
                    pageSize,
                    isMobile,
                    urgencySignal: getUrgencySignal?.(itemId) || null,
                });
            }
        },
        [
            getRegions,
            itemSearch,
            items,
            getUrgencySignal,
            searchCorrectionTerm,
            pageType,
            page,
            pageSize,
            isMobile,
            trackImpressions,
            trackedItemImpressionItems,
        ]
    );

    return {
        fireItemClickTracking,
        fireItemImpressionTracking,
    };
};
