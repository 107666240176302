import { useCallback, useEffect, useState } from 'react';
import { graphql, useFragment, useRelayEnvironment, fetchQuery } from 'react-relay';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { useClientState } from 'dibs-react-hooks/src/useClientState';
import { UrgencySignal } from '../../../utils/tracking/searchBrowse/ecommerceTracking';
import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';
import { URGENCY_SIGNALS_SB } from '../../../utils/abTestHelper';

import { useSharedUrgencySignalsQuery } from './__generated__/useSharedUrgencySignalsQuery.graphql';
import { useSharedUrgencySignals_item$key } from './__generated__/useSharedUrgencySignals_item.graphql';

const URGENCY_SIGNALS_BATCH_SIZE = 12;

const UrgencySignalsQuery = graphql`
    query useSharedUrgencySignalsQuery($itemIds: [String]) {
        viewer {
            itemUrgencySignals(itemIds: $itemIds) {
                itemId
                urgencySignals {
                    urgencyInfoType
                    count
                    message
                }
            }
        }
    }
`;
const itemFragment = graphql`
    fragment useSharedUrgencySignals_item on Item @relay(plural: true) {
        serviceId
    }
`;

type FetchItemUrgencySignals = (index: number) => void;
type GetUrgencySignal = (itemId: string | null | undefined) => UrgencySignal;
export type UrgencySignalsMap = { [itemId: string]: UrgencySignal };

export const useSharedUrgencySignals = (
    itemRef: useSharedUrgencySignals_item$key
): {
    fetchItemUrgencySignals: FetchItemUrgencySignals;
    getUrgencySignal: GetUrgencySignal;
} => {
    const isClient = useClientState();
    const items = useFragment(itemFragment, itemRef);
    const environment = useRelayEnvironment();
    const [attemptingBatches, setAttemptingBatches] = useState<number[]>([]);
    const [attemptedBatches, setAttemptedBatches] = useState<number[]>([]);
    const [urgencySignalsMap, setUrgencySignalsMap] = useState<UrgencySignalsMap>({});

    const getUrgencySignals = useCallback(
        async (ids: string[]): Promise<void> => {
            const signalsMap: UrgencySignalsMap = {};
            if (getAbTestV2(URGENCY_SIGNALS_SB)?.variant) {
                try {
                    const data = await fetchQuery<useSharedUrgencySignalsQuery>(
                        environment,
                        UrgencySignalsQuery,
                        { itemIds: ids }
                    ).toPromise();
                    data?.viewer?.itemUrgencySignals?.forEach(signal => {
                        const itemId = signal?.itemId;
                        if (itemId) {
                            const {
                                urgencyInfoType: type,
                                count,
                                message,
                            } = signal?.urgencySignals || {};
                            signalsMap[itemId] =
                                type && message && count ? { type, message, count } : null;
                        }
                    });
                } catch {
                    // no nothing
                }
            }
            // In case call fails and signalsMap was not filled with data, we still want to indicate that fetching data is finished.
            // So create newSignals object using original itemIds
            const newSignals = ids.reduce((obj, itemId) => {
                return { ...obj, [itemId]: signalsMap[itemId] || null };
            }, <UrgencySignalsMap>{});

            setUrgencySignalsMap(prev => ({ ...prev, ...newSignals }));
        },
        [environment]
    );

    // Making a primitive string out of itemIds so useEffect would fire on item and not reference change
    const itemIdsString = items
        .map(item => item?.serviceId)
        .filter(Boolean)
        .join(',');

    useEffect(() => {
        // Skipping initial render
        if (itemIdsString && isClient) {
            setAttemptingBatches([]);
            setAttemptedBatches([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemIdsString]);

    useEffect(() => {
        for (const batchIndex of attemptingBatches) {
            if (!attemptedBatches.includes(batchIndex)) {
                const itemIds = items.map(item => item.serviceId).filter(filterFalsy);
                const start = batchIndex * URGENCY_SIGNALS_BATCH_SIZE;
                const batch = [
                    ...new Set(
                        itemIds
                            .slice(start, start + URGENCY_SIGNALS_BATCH_SIZE)
                            .filter(itemId => !urgencySignalsMap[itemId])
                    ),
                ];
                getUrgencySignals(batch);
                setAttemptedBatches(prev => [...prev, batchIndex]);
            }
        }
    }, [attemptedBatches, attemptingBatches, getUrgencySignals, items, urgencySignalsMap]);

    const fetchItemUrgencySignals: FetchItemUrgencySignals = index => {
        const currentBatchIndex = Math.floor(index / URGENCY_SIGNALS_BATCH_SIZE);
        setAttemptingBatches(prev =>
            prev.includes(currentBatchIndex) ? prev : [...prev, currentBatchIndex]
        );
    };

    const getUrgencySignal: GetUrgencySignal = useCallback(
        itemId => {
            return itemId && hasKey(urgencySignalsMap, itemId) ? urgencySignalsMap[itemId] : null;
        },
        [urgencySignalsMap]
    );

    return { fetchItemUrgencySignals, getUrgencySignal };
};
